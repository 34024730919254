import { vertexShader, fragmentShader } from './tube';
import { colorRed1Vec,  colorRed2Vec,  colorRed1FracVec,  colorRed2FracVec, } from './tube';

AFRAME.registerComponent("main-district-heating-tube-1", {
  schema: {
      flowSpeed: { default: 0.2 }, // Speed of flow
      fraction: { default: 0.5 }, // fraction variable for the "heat" (color) of the cable
  },
  init: function () {
      const height = -5.5;
      const len = 4;
      const sta = new THREE.Vector3(15, height, -20);
      const end = new THREE.Vector3(sta.x, height, sta.z + 19.5);
      const flowDirection = new THREE.Vector2(0, 1);
      const radius = 0.4;
      const coldPoints = [
        end,
        sta
      ];
      const curve = new THREE.CatmullRomCurve3(coldPoints);
      const tubeGeometry = new THREE.TubeGeometry(curve, 10, radius, 8, false);
      const material = new THREE.ShaderMaterial({
        uniforms: {
          time: { value: 0 },
          flowDirection: { value: flowDirection },
          flowSpeed: { value: this.data.flowSpeed },
          stripeWidth: { value: 0.1 }, // Adjust stripe width as needed
          opacity: { value: 0.8 },
          fraction: { value: this.data.fraction },
          color1: { value: colorRed1Vec },
          color2: { value: colorRed2Vec },
          colorFrac1: { value: colorRed1FracVec },
          colorFrac2: { value: colorRed2FracVec },
        },
        vertexShader: vertexShader,
        fragmentShader: fragmentShader
      });
      const tubeMesh = new THREE.Mesh(tubeGeometry, material);
      this.el.setObject3D("tubeMesh", tubeMesh);

      this.el.sceneEl.addEventListener("tick", this.tick.bind(this));
  },
   tick: function (time, deltaTime) {
      const dt = (deltaTime / 1000) * this.data.flowSpeed;
      // Update time uniforms for shader animation
      this.el.getObject3D("tubeMesh").material.uniforms.time.value += dt;
      this.el.getObject3D("tubeMesh").material.uniforms.fraction.value = this.data.fraction;
    },
});
